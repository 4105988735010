import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory, useLocation } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Modal,
  Card,
  CardImg
} from "reactstrap"
import * as url from "helpers/url_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { AvForm, AvField } from "availity-reactstrap-validation"

import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import ScrollContainer from "react-indiana-drag-scroll"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/ugifts/loginbg.png"
import { Button } from "@material-ui/core"
import sronLogo from "assets/images/danawa/sron-logo.png"

// import bannerBlank from "assets/images/ugifts/icons/blank/banner_blank.png"
import bannerBlank from "assets/images/danawa/banner.png"
import morebg from "assets/images/ugifts/more_bg.png"

import dummy1 from "assets/images/danawa/1.png"
import dummy2 from "assets/images/danawa/2.png"

import dummyArticle1 from "assets/images/danawa/article-1.png"
import dummyArticle2 from "assets/images/danawa/article-2.png"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const imageDummy = [dummy1, dummy2]

const DanawaDashboard = props => {
  const history = useHistory()
  const query = useQuery()

  const [pwVisibility, setpwVisibility] = useState(true)

  const togglePwVisibility = () => {
    setpwVisibility(!pwVisibility)
  }

  const [token, setToken] = useState(null)
  const [countryCode, setCountryCode] = useState("65")
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [password, setPassword] = useState(null)
  const [type, setType] = useState(null)
  const [oid, setOid] = useState(null)

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }
    } else {
      getToken()
    }

    if (query.get("type")) {
      setType(query.get("type"))
    }

    if (query.get("id")) {
      setOid(query.get("id"))
      localStorage.setItem("redirect_id", query.get("id"))
    }

    if (localStorage.getItem("countryCode")) {
      setCountryCode(localStorage.getItem("countryCode"))
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUid(obj.id)
      console.log(obj)
    }
  }, [])

  const getToken = async () => {
    return;
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log("error", error))

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }

  const submitLogin = async e => {
    e.preventDefault()
    if (countryCode == null) {
      console.log("country code empty")
      seterror_alert(true)
      setdynamic_title(props.t("Country code empty"))
      setdynamic_description(props.t("Please choose a country code."))
      return
    }

    if (phoneNumber == null) {
      console.log("phone number empty")
      seterror_alert(true)
      setdynamic_title(props.t("Phone number empty"))
      setdynamic_description(props.t("Please enter your phone number."))
      return
    }

    if (password == null) {
      console.log("password empty")
      seterror_alert(true)
      setdynamic_title(props.t("Password empty"))
      setdynamic_description(props.t("Please enter your password."))
      return
    }

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "login")
    urlencoded.append("phone_number", countryCode + phoneNumber)
    urlencoded.append("password", password)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("login", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_POST_AUTH,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200 && postresponse.success) {
      if (postresponse.success) {
        if (!postresponse.migrate_member) {
          localStorage.setItem("authUser", JSON.stringify(postresponse.data))
          if (postresponse.data.country_id) {
            // localStorage.setItem("country", postresponse.data.country_id)
            localStorage.setItem("country", 3)
          }

          if (type == "redirect" && oid != null) {
            history.push("/giftcard-share?id=" + oid)
          } else history.push("/home")
        } else {
          console.log("migration")
          localStorage.setItem("phoneNumber", countryCode + phoneNumber)

          setsuccess_dlg(true)
          setdynamic_title(props.t("Password expired"))
          setdynamic_description(postresponse.message)
        }
      }
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Login failed"))
      setdynamic_description(postresponse.message)
    }
  }

  const [countryData, setCountryData] = useState(null)
  const getCountryList = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_country")
    urlencoded.append("order_by", "ASC")

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      headers: {
        // "Content-Type": "application/json",
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    // console.log("Request country", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_PROFILE,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    // console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setCountryData(postresponse.table)
      if (postresponse.table.length > 0 && countryCode == null) {
        setCountryCode(postresponse.table[0].country_code.replace("+", ""))
      }
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    getBanners()
    getArticles()
    if (token) {
      getCountryList()
    }
  }, [token])

  function onCountryCodeChange(e) {
    setCountryCode(e.target.value)
  }

  const [loginEnabled, setLoginEnabled] = useState(false)

  function onPhoneNumberChange(e) {
    setPhoneNumber(e.target.value)
    if (e.target.value != "" && password != null && password.length >= 6)
      setLoginEnabled(true)
    else setLoginEnabled(false)
  }

  function onPasswordChange(e) {
    setPassword(e.target.value)
    if (e.target.value.length >= 6 && phoneNumber != "" && phoneNumber != null)
      setLoginEnabled(true)
    else setLoginEnabled(false)
  }

  const [success_dlg, setsuccess_dlg] = useState(false)
  const [success_dlg_noaction, setsuccess_dlg_noaction] = useState(false)
  const [error_alert, seterror_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")  

  function onClickFBLogin() {
    FB.login(
      function (response) {
        if (response.authResponse) {
          console.log("Welcome!  Fetching your information.... ")
          FB.api(
            "/me?fields=id,name,email,picture.width(300).height(300),friends",
            function (response) {
              console.log("Good to see you", response)
              setFbResponse(response)
            }
          )
        } else {
          console.log("User cancelled login or did not fully authorize.")
        }
      },
      { scope: "public_profile,user_friends" }
    )
  }

  const [fbResponse, setFbResponse] = useState(null)
  const responseFacebook = response => {
    console.log("facebook", response)
    if (response.accessToken) {
      setFbResponse(response)
      localStorage.setItem("fbToken", response.accessToken)
    }
    // setData(response)
    // setPicture(response.picture.data.url)
    // if (response.accessToken) {
    //   setLogin(true)
    // } else {
    //   setLogin(false)
    // }
  }

  useEffect(() => {
    if (fbResponse) loginWithFacebook()
  }, [fbResponse])

  const [uid, setUid] = useState(null)
  const loginWithFacebook = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "login_facebook")
    urlencoded.append("facebook_id", fbResponse.id)
    urlencoded.append("full_name", fbResponse.name)
    urlencoded.append("profile_picture_link", fbResponse.picture.data.url)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("login with fb", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_POST_AUTH,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200 && postresponse.success) {
      if (postresponse.success) {
        console.log("success", postresponse)
        setUid(postresponse.data.member_id)
        localStorage.setItem("authUser", JSON.stringify(postresponse.data))
        if (postresponse.data.country_id != "0") {
          // localStorage.setItem("country", postresponse.data.country_id)
          localStorage.setItem("country", 3)
          // console.log("go to home")
          history.push("/home")
        } else {
          tog_yt()
        }
      }
    } else {
      seterror_alert(true)
      setdynamic_title("Login Facebook failed")
      setdynamic_description(postresponse.message)
    }
  }

  const [modal_yt, setmodal_yt] = useState(false)

  function tog_yt() {
    setmodal_yt(!modal_yt)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const [referralCode, setReferralCode] = useState(null)
  function onReferralCodeChange(e) {
    setReferralCode(e.target.value)
  }

  const [countryId, setCountryId] = useState(null)
  function onCountryIdChange(e) {
    setCountryId(e.target.value)
  }

  const updateCountry = async () => {
    if (countryId == null) {
      console.log("country code empty")
      seterror_alert(true)
      setdynamic_title(props.t("Country empty"))
      setdynamic_description(props.t("Please choose a country."))
      return
    }

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "update_country")
    urlencoded.append("country_id", countryId)
    urlencoded.append("member_id", uid)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("login", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_POST_AUTH,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200 && postresponse.success) {
      if (postresponse.success) {
        // localStorage.setItem("country", countryId)
        localStorage.setItem("country", 3)
        if (referralCode) submitReferralCode(uid)
        else {
          history.push("/home")
          // console.log("go to home")
        }
      }
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Login failed"))
      setdynamic_description(postresponse.message)
    }
  }

  const submitReferralCode = async uid => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "apply_referral_code")
    urlencoded.append("member_id", uid)
    urlencoded.append("referral_code", referralCode)

    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token.api_token,
      },
      body: urlencoded,
    }

    console.log("Apply referral", urlencoded.toString())

    const response = await fetch(
      url.UGIFT_STAGING_PROFILE,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // console.log("go to home")
      history.push("/home")
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Referral update failed"))
      setdynamic_description(postresponse.message)
    }
  }

  const [package_selected, setpackage_selected] = useState("Public")
  const [package_list, setpackage_list] = useState([])
  const getPackages = async type => {
    // var form = JSON.stringify({
    //   option: "list_package",
    //   type: type,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_package")
    urlencoded.append("type", type)
    // urlencoded.append("password", password)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request packages", urlencoded)

    const response = await fetch(
      url.DANAWA_POST_MEMBER,
      requestOptions
    )
    const postresponse = await response.json()

    console.log("list_package", postresponse)

    if (response.status == 200 && postresponse.success) {
      setpackage_list(postresponse.data)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (package_selected) getPackages(package_selected)
  }, [package_selected])
  
  const [bannerData, setbannerData] = useState([])
  const getBanners = async () => {
    // var form = JSON.stringify({
    //   option: "list_package",
    //   type: type,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_banner")
    // urlencoded.append("type", type)
    // urlencoded.append("password", password)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request banner", urlencoded)

    const response = await fetch(url.DANAWA_POST_FRONTEND, requestOptions)
    const postresponse = await response.json()

    console.log("list_banner", postresponse)

    if (response.status == 200 && postresponse.success) {
      setbannerData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  const [articleData, setArticleData] = useState([])
  const getArticles = async () => {
    // var form = JSON.stringify({
    //   option: "list_package",
    //   type: type,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_article")
    // urlencoded.append("type", type)
    // urlencoded.append("password", password)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request articles", urlencoded)

    const response = await fetch(url.DANAWA_POST_FRONTEND, requestOptions)
    const postresponse = await response.json()

    console.log("list_articles", postresponse)

    if (response.status == 200 && postresponse.success) {
      setArticleData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (uid != null) {
      // if (!isGuest) {
      getUserProfile()
      //   getFriendList()
      //   getOrderList()
      //   getBasketData()
      //   getUdollar()
      // }
      console.log("use effect")
    }
  }, [uid])

  const [userData, setUserData] = useState(null)
  const getUserProfile = async () => {
    // var form = JSON.stringify({
    //   option: "detail_profile",
    //   id: uid,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "detail_profile")
    urlencoded.append("id", uid)

    const requestOptions = {
      method: "POST",
      // mode: "no-cors",
      // headers: {
      //   // "Content-Type": "application/json",
      //   Authorization: "Bearer " + token.api_token,
      // },
      body: urlencoded,
    }

    console.log("Request user profile", urlencoded)

    const response = await fetch(
      url.DANAWA_POST_MEMBER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setUserData(postresponse.data)
    } else {
      // seterror_alert(true)
    }
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="">
            {/* <CarouselPage /> */}
            {success_dlg_noaction ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg_noaction(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {success_dlg ? (
              <SweetAlert
                info
                title={dynamic_title}
                onConfirm={() => {
                  history.push("/reset-password")
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {error_alert ? (
              <SweetAlert
                title={dynamic_title}
                danger
                onConfirm={() => {
                  seterror_alert(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            <Col
              // xl={6}
              className="mx-auto"
              // style={{
              //   // maxWidth: "480px",
              //   backgroundImage: `url(${bgImage})`,
              //   backgroundSize: "cover",
              // }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                className="auth-full-page-content"
                style={{ minHeight: "100vh" }}
              >
                <div className="w-100">
                  <div className="curved" />
                  <div
                    className="d-flex flex-column h-100"
                    style={{
                      paddingBottom: "108px",
                    }}
                  >
                    <Row className="justify-content-center">
                      <Col lg={"10"} xs={"11"}>
                        <Row className="px-3 mb-3 mt-5 mx-0">
                          <Col>
                            <img
                              src={sronLogo}
                              alt=""
                              style={{
                                // position: "absolute",
                                objectFit: "contain",
                                maxHeight: "40px",
                              }}
                            />
                          </Col>
                          <Col
                            xs={"auto"}
                            className="pe-0 justify-content-center font-size-16 align-items-end"
                          >
                            {props.t(
                              "Welcome, " + (userData && userData.username)
                            )}
                            <Link to="/profile">
                              <button className="btn btn-primary btn-rounded btn-block waves-effect waves-light font-size-16 text-uppercase ms-2">
                                <i className="bx bx-user align-middle font-size-16 text-white" />
                              </button>
                            </Link>
                          </Col>
                        </Row>
                        <Row>
                          <Carousel
                            infiniteLoop
                            autoPlay
                            swipeable
                            emulateTouch
                            interval={3000}
                            showThumbs={false}
                            className="slider_css"
                            dir="rtl"
                            onClickItem={e => {
                              // bannerData &&
                              //   onClickBanner(
                              //     bannerData[e].linkage_type,
                              //     bannerData[e].linkage_detail
                              //   )
                            }}
                          >
                            {bannerData &&
                              bannerData.map((banner, index) => (
                                <div key={"banner-" + index}>
                                  <div className="item">
                                    <Card className="text-center px-3 pt-0 shadow-none bg-transparent">
                                      <CardImg
                                        top
                                        className="img-fluid"
                                        style={{
                                          // height: "280px",
                                          objectFit: "cover",
                                          borderRadius: "14px",
                                          aspectRatio: "3/1",
                                        }}
                                        src={banner.image}
                                        alt=""
                                        onError={e => {
                                          onImageError(e, bannerBlank)
                                        }}
                                      />
                                    </Card>
                                  </div>
                                </div>
                              ))}
                            {/* <div>
                              <div className="item">
                                <Card className="text-center px-3 pt-0 shadow-none bg-transparent">
                                  <CardImg
                                    top
                                    className="img-fluid"
                                    style={{
                                      // height: "280px",
                                      objectFit: "cover",
                                      borderRadius: "14px",
                                      aspectRatio: "3/1",
                                    }}
                                    src={bannerBlank}
                                    alt=""
                                    onError={e => {
                                      onImageError(e, bannerBlank)
                                    }}
                                  />
                                </Card>
                              </div>
                            </div> */}
                          </Carousel>
                        </Row>

                        <Row
                          className="m-3 mt-5 d-flex bg-primary"
                          style={{ borderRadius: "12px" }}
                        >
                          <Col lg={"2"} sm={"3"} xs={"4"}>
                            <button
                              className={
                                "btn btn-primary btn-block waves-effect waves-light font-size-14 w-100 text-uppercase bg-transparent border-0 p-3"
                              }
                              style={{
                                borderRadius: "12px",
                                width: "120px",
                              }}
                              onClick={() => {
                                history.push("/product-list")
                              }}
                            >
                              <Col className="mb-2" style={{ height: "30px" }}>
                                <i class="bx bx-mobile font-size-30"></i>
                              </Col>
                              <Col>{props.t("Packages")}</Col>
                            </button>
                          </Col>
                          <Col lg={"2"} sm={"3"} xs={"4"}>
                            <button
                              className={
                                "btn btn-primary btn-block waves-effect waves-light font-size-14 w-100 text-uppercase bg-transparent border-0 p-3"
                              }
                              style={{
                                borderRadius: "12px",
                                width: "120px",
                              }}
                              onClick={() => {
                                setpackage_selected("Public")
                              }}
                            >
                              <Col className="mb-2" style={{ height: "30px" }}>
                                <i class="bx bx-receipt font-size-30"></i>
                              </Col>
                              <Col>{props.t("My Order")}</Col>
                            </button>
                          </Col>
                          <Col lg={"2"} sm={"3"} xs={"4"}>
                            <button
                              className={
                                "btn btn-primary btn-block waves-effect waves-light font-size-14 w-100 text-uppercase bg-transparent border-0 p-3"
                              }
                              style={{
                                borderRadius: "12px",
                                width: "120px",
                              }}
                              onClick={() => {
                                setpackage_selected("Public")
                              }}
                            >
                              <Col className="mb-2" style={{ height: "30px" }}>
                                <i class="bx bxs-user-account font-size-30"></i>
                              </Col>
                              <Col>{props.t("My Account")}</Col>
                            </button>
                          </Col>
                          <Col lg={"2"} sm={"3"} xs={"4"}>
                            <button
                              className={
                                "btn btn-primary btn-block waves-effect waves-light font-size-14 w-100 text-uppercase bg-transparent border-0 p-3"
                              }
                              style={{
                                borderRadius: "12px",
                                width: "120px",
                              }}
                              onClick={() => {
                                window.open("https://bit.ly/3jts6jS", "_blank")
                              }}
                            >
                              <Col className="mb-2" style={{ height: "30px" }}>
                                <i class="bx bx-cloud-download font-size-30"></i>
                              </Col>
                              <Col>{props.t("Download SPay")}</Col>
                            </button>
                          </Col>
                          <Col lg={"2"} sm={"3"} xs={"4"}>
                            <button
                              className={
                                "btn btn-primary btn-block waves-effect waves-light font-size-14 w-100 text-uppercase bg-transparent border-0 p-3"
                              }
                              style={{
                                borderRadius: "12px",
                                width: "120px",
                              }}
                              onClick={() => {
                                history.push("/contact-us")
                              }}
                            >
                              <Col className="mb-2" style={{ height: "30px" }}>
                                <i class="bx bx-support font-size-30"></i>
                              </Col>
                              <Col>{props.t("Support")}</Col>
                            </button>
                          </Col>
                        </Row>

                        {/* <Row className="d-none d-lg-block mt-5">{""}</Row> */}
                        {/* <Row className="" style={{ marginTop: "50px" }}>
                          <Col>
                            <ScrollContainer className="scroll-container px-3">
                              <Row className="flex-nowrap">
                                <Col sm={6} xs={7} className="d-lg-none">
                                  <button
                                    className={
                                      package_selected == "Public"
                                        ? "btn btn-primary btn-block waves-effect waves-light w-100 font-size-14 text-uppercase"
                                        : "btn btn-light btn-block waves-effect waves-light w-100 font-size-14 text-uppercase text-muted"
                                    }
                                    style={{
                                      borderRadius: "12px",
                                      aspectRatio: "9/2",
                                    }}
                                    onClick={() => {
                                      setpackage_selected("Public")
                                    }}
                                  >
                                    <Row className="justify-content-center align-items-center">
                                      <Col xs="auto" className="pe-0">
                                        <i class="bx bx-wifi font-size-20"></i>
                                      </Col>
                                      <Col xs="auto">
                                        <strong>
                                          {props.t("Public Wifi")}
                                        </strong>
                                      </Col>
                                    </Row>
                                  </button>
                                </Col>
                                <Col sm={6} xs={7} className="d-lg-none">
                                  <button
                                    className={
                                      package_selected == "Home"
                                        ? "btn btn-primary btn-block waves-effect waves-light w-100 font-size-14 text-uppercase"
                                        : "btn btn-light btn-block waves-effect waves-light w-100 font-size-14 text-uppercase text-muted"
                                    }
                                    style={{
                                      borderRadius: "12px",
                                      aspectRatio: "9/2",
                                    }}
                                    onClick={() => {
                                      setpackage_selected("Home")
                                    }}
                                  >
                                    <Row className="justify-content-center align-items-center">
                                      <Col xs="auto" className="pe-0">
                                        <i class="bx bx-building-house font-size-20"></i>
                                      </Col>
                                      <Col xs="auto">
                                        <strong>
                                          {props.t("Home Connect")}
                                        </strong>
                                      </Col>
                                    </Row>
                                  </button>
                                </Col>
                                <Col sm={6} xs={7} className="d-lg-none">
                                  <button
                                    className={
                                      package_selected == "Corporate"
                                        ? "btn btn-primary btn-block waves-effect waves-light w-100 font-size-14 text-uppercase"
                                        : "btn btn-light btn-block waves-effect waves-light w-100 font-size-14 text-uppercase text-muted"
                                    }
                                    style={{
                                      borderRadius: "12px",
                                      aspectRatio: "9/2",
                                    }}
                                    onClick={() => {
                                      setpackage_selected("Corporate")
                                    }}
                                  >
                                    <Row className="justify-content-center align-items-center">
                                      <Col xs="auto" className="pe-0">
                                        <i class="bx bx-briefcase font-size-20"></i>
                                      </Col>
                                      <Col xs="auto">
                                        <strong>
                                          {props.t("Corporate Connect")}
                                        </strong>
                                      </Col>
                                    </Row>
                                  </button>
                                </Col>
                                <Col
                                  xl={3}
                                  lg={4}
                                  className="d-none d-lg-block me-5"
                                >
                                  <button
                                    className={
                                      package_selected == "Public"
                                        ? "btn btn-primary btn-block waves-effect waves-light w-100 font-size-16 text-uppercase align-item-center"
                                        : "btn btn-light btn-block waves-effect waves-light w-100 font-size-16 text-uppercase text-muted align-item-center"
                                    }
                                    style={{
                                      borderRadius: "12px",
                                      aspectRatio: "9/2",
                                    }}
                                    onClick={() => {
                                      setpackage_selected("Public")
                                    }}
                                  >
                                    <Row className="justify-content-center align-items-center">
                                      <Col xs="auto" className="pe-0">
                                        <i class="bx bx-wifi font-size-24"></i>
                                      </Col>
                                      <Col xs="auto">
                                        <strong>
                                          {props.t("Public Wifi")}
                                        </strong>
                                      </Col>
                                    </Row>
                                  </button>
                                </Col>
                                <Col
                                  xl={3}
                                  lg={4}
                                  className="d-none d-lg-block me-5"
                                >
                                  <button
                                    className={
                                      package_selected == "Home"
                                        ? "btn btn-primary btn-block waves-effect waves-light w-100 font-size-16 text-uppercase"
                                        : "btn btn-light btn-block waves-effect waves-light w-100 font-size-16 text-uppercase text-muted"
                                    }
                                    style={{
                                      borderRadius: "12px",
                                      aspectRatio: "9/2",
                                    }}
                                    onClick={() => {
                                      setpackage_selected("Home")
                                    }}
                                  >
                                    <Row className="justify-content-center align-items-center">
                                      <Col xs="auto" className="pe-0">
                                        <i class="bx bx-building-house font-size-24"></i>
                                      </Col>
                                      <Col xs="auto">
                                        <strong>
                                          {props.t("Home Connect")}
                                        </strong>
                                      </Col>
                                    </Row>
                                  </button>
                                </Col>
                                <Col
                                  xl={3}
                                  lg={4}
                                  className="d-none d-lg-block me-5"
                                >
                                  <button
                                    className={
                                      package_selected == "Corporate"
                                        ? "btn btn-primary btn-block waves-effect waves-light w-100 font-size-16 text-uppercase"
                                        : "btn btn-light btn-block waves-effect waves-light w-100 font-size-16 text-uppercase text-muted"
                                    }
                                    style={{
                                      borderRadius: "12px",
                                      aspectRatio: "9/2",
                                    }}
                                    onClick={() => {
                                      setpackage_selected("Corporate")
                                    }}
                                  >
                                    <Row className="justify-content-center align-items-center">
                                      <Col xs="auto" className="pe-0">
                                        <i class="bx bx-briefcase font-size-24"></i>
                                      </Col>
                                      <Col xs="auto">
                                        <strong>
                                          {props.t("Corporate Connect")}
                                        </strong>
                                      </Col>
                                    </Row>
                                  </button>
                                </Col>
                              </Row>
                            </ScrollContainer>
                          </Col>
                        </Row> */}
                        {/* <Row className="d-none d-lg-block mt-5">{""}</Row> */}
                        {/* <Row className="mt-3">
                          <Col>
                            <ScrollContainer className="scroll-container px-3">
                              <Row className="flex-nowrap">
                                {package_list &&
                                  package_list.map((object, index) => (
                                    <Col
                                      lg={4}
                                      className="me-4"
                                      key={"package-" + index}
                                    >
                                      <Link
                                        to={"/product-details?id=" + object.id}
                                      >
                                        <Card
                                          className="p-0 shadow-none"
                                          style={{
                                            borderRadius: "14px",
                                            // width: "30%",
                                            // height: "106px",
                                            backgroundColor: "#DEDEDE",
                                            backgroundImage: `url("${object.image}")`,
                                            backgroundSize: "cover",
                                            aspectRatio: "17/11",
                                          }}
                                        >
                                          <Row
                                            className="mt-auto justify-content-end mx-0"
                                            style={{
                                              backgroundImage:
                                                "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6))",
                                              borderBottomRightRadius: "14px",
                                              borderBottomLeftRadius: "14px",
                                              height: "40px",
                                            }}
                                          >
                                            <Col
                                              xs="auto"
                                              className="align-self-center"
                                            >
                                              <span
                                                className="font-size-16 text-white"
                                                style={{ fontWeight: 700 }}
                                              >
                                                {object.name}
                                              </span>
                                            </Col>
                                          </Row>
                                        </Card>
                                      </Link>
                                    </Col>
                                  ))}
                              </Row>
                            </ScrollContainer>
                          </Col>
                        </Row> */}
                        <Row className="d-none d-lg-block mt-5">{""}</Row>
                        <Row className="px-3 mb-3 mt-3">
                          <Col xs="6">
                            <span
                              className="mb-0 font-size-20"
                              style={{ fontWeight: 700 }}
                            >
                              {props.t("Articles")}
                            </span>
                          </Col>
                          <Col
                            xs="6"
                            className="font-size-14 text-end align-self-center"
                            style={{ fontWeight: 500 }}
                          >
                            <Link to="/article-list">{props.t("See all")}</Link>
                          </Col>
                        </Row>

                        <Row className="">
                          <Col>
                            <ScrollContainer className="scroll-container px-3">
                              <Row className="flex-nowrap">
                                {/* {discoverData &&
                              discoverData.map((data, index) => (
                                <Col xs="auto">
                                  <Link
                                    to={
                                      "/products?id=" +
                                      data.id +
                                      "&type=list_category"
                                    }
                                  >
                                    <Card
                                      className="p-3 shadow-none"
                                      style={{
                                        borderRadius: "14px",
                                        width: "160px",
                                        height: "106px",
                                        backgroundColor: "#DEDEDE",
                                        backgroundImage: `url("${data.image_home}")`,
                                        backgroundSize: "cover",
                                      }}
                                    >
                                      <Row className="mt-auto">
                                        <Col>
                                          <span
                                            className="font-size-12 text-dark"
                                            style={{ fontWeight: 700 }}
                                          >
                                            {
                                              multiLanguageHandler(
                                                data.multiple_languages
                                              ).name
                                            }
                                          </span>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Link>
                                </Col>
                              ))} */}
                                {articleData &&
                                  articleData.map((article, index) => (
                                    <Col lg={5} xs={11} className="me-3">
                                      <Link
                                        to={"/article-details?id=" + article.id}
                                      >
                                        <Card
                                          className="p-0 shadow-none"
                                          style={{
                                            borderRadius: "14px",
                                            // width: "30%",
                                            // height: "106px",
                                            backgroundColor: "#DEDEDE",
                                            backgroundImage: `url("${article.image}")`,
                                            backgroundSize: "cover",
                                            aspectRatio: "17/11",
                                          }}
                                        >
                                          <Row
                                            className="mt-auto justify-content-end mx-0"
                                            style={{
                                              backgroundImage:
                                                "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6))",
                                              borderBottomRightRadius: "14px",
                                              borderBottomLeftRadius: "14px",
                                              height: "40px",
                                            }}
                                          >
                                            <Col
                                              xs="auto"
                                              className="align-self-center"
                                            >
                                              <span
                                                className="font-size-24 text-white"
                                                style={{ fontWeight: 700 }}
                                              >
                                                {props.t(article.title)}
                                              </span>
                                            </Col>
                                          </Row>
                                        </Card>
                                      </Link>
                                    </Col>
                                  ))}
                                {/* <Col lg={6} className="me-4">
                                  <Link to="">
                                    <Card
                                      className="p-0 shadow-none"
                                      style={{
                                        borderRadius: "14px",
                                        // width: "30%",
                                        // height: "106px",
                                        backgroundColor: "#DEDEDE",
                                        backgroundImage: `url("${dummyArticle2}")`,
                                        backgroundSize: "cover",
                                        aspectRatio: "17/11",
                                      }}
                                    >
                                      <Row
                                        className="mt-auto justify-content-end mx-0"
                                        style={{
                                          backgroundImage:
                                            "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6))",
                                          borderBottomRightRadius: "14px",
                                          borderBottomLeftRadius: "14px",
                                          height: "40px",
                                        }}
                                      >
                                        <Col
                                          xs="auto"
                                          className="align-self-center"
                                        >
                                          <span
                                            className="font-size-16 text-white"
                                            style={{ fontWeight: 700 }}
                                          >
                                            {props.t("")}
                                          </span>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Link>
                                </Col> */}
                              </Row>
                            </ScrollContainer>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal_yt}
        toggle={() => {
          tog_yt()
        }}
        // className="modal modal-static"
        // centered={true}
        backdrop={"static"}
        id="staticBackdrop"
      >
        {/* <div className="modal-header" style={{ borderBottom: "none" }}>
          <h5 className="modal-title mt-0" id="myModalLabel">
            {"Choose Location"}
          </h5>
          <button
            type="button"
            onClick={() => {
              tog_yt(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div> */}
        <div className="modal-body">
          <AvForm>
            <Row>
              <Label
                className="font-size-15 font-weight-bold text-center"
                style={{ color: "#616E7C" }}
              >
                {props.t("Choose your country")}
              </Label>
              <Col>
                <AvField
                  className="mb-3 form-select font-size-15"
                  type="select"
                  // label={props.t("Participant")}
                  // helpMessage={props.t("Gender Placeholder")}
                  // value={form.country_id}
                  name="country_id"
                  onChange={onCountryIdChange}
                  errorMessage={"Field required"}
                  validate={{ required: { value: true } }}
                  style={{
                    borderRadius: "8px",
                    height: "50px",
                  }}
                >
                  {countryData &&
                    countryData.map((data, index) => (
                      <option key={"country-option-" + index} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                </AvField>
              </Col>
            </Row>
          </AvForm>
          <Row className="justify-content-center">
            <Label
              className="font-size-15 font-weight-bold text-center"
              style={{ color: "#616E7C" }}
            >
              {props.t("Enter your referral code")}
            </Label>
            <Col>
              <InputGroup>
                <Input
                  type="text"
                  className="form-control font-size-15 text-center"
                  id="userReferral"
                  placeholder={props.t("Enter your referral Code")}
                  style={{
                    height: "50px",
                    // border: "none",
                    borderTopLeftRadius: "8px",
                    borderBottomLeftRadius: "8px",
                  }}
                  onChange={onReferralCodeChange}
                />
              </InputGroup>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              updateCountry()
              // tog_yt()
            }}
            className="btn btn-primary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Apply")}
          </button>
          {/* <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button> */}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaDashboard)

DanawaDashboard.propTypes = {
  t: PropTypes.any,
}
