import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Card,
  CardImg,
  CardTitle,
  Modal,
} from "reactstrap"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as url from "helpers/url_helper"
import SweetAlert from "react-bootstrap-sweetalert"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

//empty state
// import UgiftEmptyState from "./UgiftEmptyState"
import emptyImage from "assets/images/ugifts/icons/empty/empty_outlet.png"
import iconSuccess from "assets/images/danawa/icon_success_red.png"
import iconFailed from "assets/images/danawa/icon_failed_red.png"

import dummy1 from "assets/images/danawa/1.png"
import dummy2 from "assets/images/danawa/2.png"

const imageDummy = [dummy1, dummy2]

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const PurchaseStatus = props => {
  const query = useQuery()
  const history = useHistory()

  const [token, setToken] = useState(null)
  const [uid, setUid] = useState(null)
  const [tableData, setTableData] = useState(null)
  const [listType, setListType] = useState("detail_article")
  const [pid, setPid] = useState(null)
  const [quantity, setQuantity] = useState(1)
  const [stockLevel, setStockLevel] = useState(0)
  const [itemInBasket, setItemInBasket] = useState(false)
  const [currency, setCurrency] = useState("")
  const [basketCount, setBasketCount] = useState(null)
  const [giftcardId, setGiftcardId] = useState(null)
  const [isGuest, setIsGuest] = useState(false)
  const [oid, setOid] = useState(null)

  const [language, setLanguage] = useState("eng")

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }
    } else {
      getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUid(obj.member_id)
      console.log(obj)
    } else {
      setIsGuest(true)
    }

    if (query.get("id")) {
      setPid(query.get("id"))
    }

    if (localStorage.getItem("gid")) {
      setGiftcardId(localStorage.getItem("gid"))
    }

    if (localStorage.getItem("currency")) {
      setCurrency(localStorage.getItem("currency"))
    }

    if (localStorage.getItem("basketCount")) {
      setBasketCount(localStorage.getItem("basketCount"))
    }

    if (localStorage.getItem("I18N_LANGUAGE")) {
      setLanguage(localStorage.getItem("I18N_LANGUAGE"))
    }

    if (query.get("order")) {
      setOid(query.get("order"))
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Error"))
      setdynamic_description(props.t("Order not found."))
    }
  }, [])

  const getToken = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    console.log("UgiftZi)$rnK}K%I8PZKey", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    if (token != null) {
      // getList()
      // if (!isGuest) getBasketData()
      // if (giftcardId) getGiftList()
      console.log("use effect")
    }
  }, [token, listType])

  useEffect(() => {
    if (oid != null) {
      getOrderData()
      console.log("use effect")
    }
  }, [oid])

  const [orderData, setOrderData] = useState(null)
  const getOrderData = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_order_details")
    urlencoded.append("id", oid)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request order details", urlencoded.toString())

    const response = await fetch(
      url.DANAWA_POST_MEMBER,
      requestOptions
    )
    const postresponse = await response.json()

    console.log(response.status, postresponse)

    if (response.status == 200) {
      // history.push("/tables-applicationtable")
      setOrderData(postresponse.data)
    } else {
      seterror_alert(true)
    }
  }

  const [modal_tnc, setmodal_tnc] = useState(false)
  const [modal_redeem, setmodal_redeem] = useState(false)
  const [modal_available, setmodal_available] = useState(false)

  function tog_tnc() {
    setmodal_tnc(!modal_tnc)
    removeBodyCss()
  }

  function tog_howToRedeem() {
    setmodal_redeem(!modal_redeem)
    removeBodyCss()
  }

  function tog_available() {
    setmodal_available(!modal_available)
    removeBodyCss()
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding")
  }

  const [success_dlg, setsuccess_dlg] = useState(false)
  const [success_dlg_noaction, setsuccess_dlg_noaction] = useState(false)
  const [error_alert, seterror_alert] = useState(false)
  const [redirect_alert, setredirect_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  const multiLanguageHandler = data => {
    switch (language) {
      case "eng":
        return data.en
      case "cn":
        return data.cn ? data.cn : data.en
      case "jp":
        return data.jp ? data.jp : data.en
      default:
        return data.en
    }
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | Purchase Status</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            {success_dlg_noaction ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg_noaction(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  history.push("/basket")
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {error_alert ? (
              <SweetAlert
                title={dynamic_title}
                danger
                onConfirm={() => {
                  seterror_alert(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {redirect_alert ? (
              <SweetAlert
                title={dynamic_title}
                onCancel={() => {
                  setredirect_alert(false)
                }}
                customButtons={<React.Fragment></React.Fragment>}
              >
                <React.Fragment>
                  <Row className="px-3 py-2">
                    <button
                      className="btn btn-primary waves-effect waves-light font-size-15 me-2"
                      onClick={() => {
                        setredirect_alert(false)
                        history.push("/login")
                      }}
                    >
                      {props.t("Login")}
                    </button>
                  </Row>
                  <Row className="px-3 py-2">
                    <button
                      className="btn btn-light waves-effect waves-light font-size-15"
                      onClick={() => {
                        setredirect_alert(false)
                      }}
                    >
                      {props.t("Cancel")}
                    </button>
                  </Row>
                </React.Fragment>
              </SweetAlert>
            ) : null}
            <Col
              xl="7"
              lg="10"
              className="mx-auto"
              style={{
                // maxWidth: "480px",
                backgroundColor: "white",
                // backgroundImage: `url(${bgImage})`,
                // backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                className="auth-full-page-content"
                style={{
                  minHeight: "100vh",
                  // backgroundColor: "#F5F5F5",
                  // paddingTop: "56px",
                  paddingBottom: "105px",
                }}
              >
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <Row className="mb-3 mt-3 mx-0">
                      <Col className="align-self-center text-uppercase">
                        <span
                          className="mb-0 font-size-24 text-primary"
                          style={{ fontWeight: 700 }}
                        >
                          {props.t("P")}
                        </span>
                        <span
                          className="mb-0 font-size-24 text-secondary"
                          style={{ fontWeight: 700 }}
                        >
                          {props.t("urchase Status")}
                        </span>
                      </Col>
                    </Row>
                    {orderData && orderData.payment_status == "Paid" && (
                      <Row>
                        <Col>
                          <Card
                            className="p-3 shadow-none"
                            style={{
                              // borderRadius: "12px",
                              // width: "160px",
                              // height: "196px",
                              backgroundColor: "white",
                            }}
                          >
                            <Row className="justify-content-center">
                              <Col xs="auto">
                                <img
                                  src={iconSuccess}
                                  alt=""
                                  style={{
                                    // position: "absolute",
                                    objectFit: "contain",
                                    maxWidth: "150px",
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className="justify-content-center">
                              <Col xs="auto">
                                <CardTitle className="font-size-24 text-dark">
                                  {props.t("Payment Successful")}
                                </CardTitle>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="auto">
                                <span className="font-size-16 fw-bold text-dark">
                                  {props.t(
                                    "Please follow the instructions below to get connected"
                                  )}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="auto">
                                <span
                                  className="font-size-16 text-dark"
                                  style={{ lineHeight: 2 }}
                                >
                                  {props.t(
                                    "1. Reconnect your device to the wifi"
                                  )}
                                  <br />
                                  {props.t(
                                    "2. Login to your account in the forms popup"
                                  )}
                                  <br />
                                  {props.t("3. Enjoy your wifi!")}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    )}
                    {orderData && orderData.payment_status != "Paid" && (
                      <Row>
                        <Col>
                          <Card
                            className="p-3 shadow-none"
                            style={{
                              // borderRadius: "12px",
                              // width: "160px",
                              // height: "196px",
                              backgroundColor: "white",
                            }}
                          >
                            <Row className="justify-content-center">
                              <Col xs="auto">
                                <img
                                  src={iconFailed}
                                  alt=""
                                  style={{
                                    // position: "absolute",
                                    objectFit: "contain",
                                    maxWidth: "150px",
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row className="justify-content-center">
                              <Col xs="auto">
                                <CardTitle className="font-size-24 text-dark">
                                  {props.t("Payment Failed")}
                                </CardTitle>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="auto">
                                <span className="font-size-16 fw-bold text-dark">
                                  {props.t(
                                    "Please try again later. This may happen when"
                                  )}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="auto">
                                <span
                                  className="font-size-16 text-dark"
                                  style={{ lineHeight: 2 }}
                                >
                                  {props.t(
                                    "1. Page refresh during payment"
                                  )}
                                  <br />
                                  {props.t(
                                    "2. Payment cancelled by user"
                                  )}
                                  <br />
                                  {props.t("3. Payment timeout")}
                                </span>
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </Row>
                    )}
                  </div>
                  <div className="mt-auto">
                    <Row className="p-4 bg-secondary g-0 justify-content-center">
                      <Col lg={10}>
                        <Link to="/dashboard">
                          <button
                            className={
                              "btn btn-primary btn-block waves-effect waves-light w-100 font-size-18 py-3 text-uppercase"
                            }
                            // type="submit"
                            style={{ borderRadius: "12px" }}
                          >
                            <strong>{props.t("Back to Dashboard")}</strong>
                          </button>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Modal
        isOpen={modal_tnc}
        toggle={() => {
          tog_tnc()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Terms & Conditions")}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_tnc(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {/* <div
          className="modal-body"
          dangerouslySetInnerHTML={
            tableData && {
              __html: multiLanguageHandler(tableData.multiple_languages)
                .terms_and_conditions,
            }
          }
        ></div> */}
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_tnc()
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Close")}
          </button>
          {/* <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button> */}
        </div>
      </Modal>
      <Modal
        isOpen={modal_redeem}
        toggle={() => {
          tog_howToRedeem()
        }}
      >
        <div className="modal-header">
          <span className="modal-title mt-0" id="myModalLabel">
            {props.t("How to redeem")}
          </span>
          <button
            type="button"
            onClick={() => {
              setmodal_tnc(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        {/* <div
          className="modal-body"
          dangerouslySetInnerHTML={
            tableData && {
              __html: multiLanguageHandler(tableData.multiple_languages)
                .redeem_howto,
            }
          }
        ></div> */}
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_howToRedeem()
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Close")}
          </button>
          {/* <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button> */}
        </div>
      </Modal>
      <Modal
        isOpen={modal_available}
        toggle={() => {
          tog_available()
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Available Outlet")}
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_available(false)
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {tableData &&
            tableData.applicable_outlets &&
            // typeof tableData.applicable_outlets != "object" &&
            tableData.applicable_outlets.map((outlet, index) => (
              <div>
                <Row className="mb-2">
                  <Col>
                    <strong>
                      <span className="font-size-15">{outlet.outlet_name}</span>
                    </strong>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <span className="font-size-15">{outlet.address}</span>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <span className="font-size-15">{outlet.outlet_telno}</span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span className="font-size-15">{outlet.email}</span>
                  </Col>
                </Row>
                <hr
                  style={{
                    color: "#EAEAEA",
                    backgroundColor: "#EAEAEA",
                    height: "2px",
                  }}
                />
              </div>
            ))}
          {/* {tableData &&
            tableData.applicable_outlets &&
            tableData.applicable_outlets.length == 0 && (
              <UgiftEmptyState
                image={emptyImage}
                title={props.t("No outlets available")}
                link={null}
                linkText=""
              />
            )} */}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_available()
            }}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            {props.t("Close")}
          </button>
          {/* <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
          >
            Save changes
          </button> */}
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default withTranslation()(PurchaseStatus)

PurchaseStatus.propTypes = {
  t: PropTypes.any,
}
